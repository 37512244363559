<template>
  <!-- 页脚 -->
  <div class="footer">
    <div class="footerIn">
      <div class="footerInTop">
        <span class="footerInTopLeft">
          <span >
             电商数据化工具箱<span>|</span>专注用户体验,深挖用户需求,技术驱动
          </span>
        </span>
        <span class="declaration">
          <router-link tag="a" target="_blank" :to="{path:'/statement'}">免责声明</router-link>
        </span>
        <!-- <div class="footerInTopRight">
          <p class="footerInTopRightTitle">合作伙伴</p>
          <div style="display: flex">
            <div
              v-for="(item, index) in cooperation"
              :key="index"
              style="margin-right: 10px"
            >
              <img
                style="width: 120px; height: 50px"
                :src="item.imgSrc"
                alt=""
              />
              <div style="text-align: center">{{ item.text }}</div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="footerInBottom">
        <span>{{$store.state.title}} © 2019-2022 All Rights Reserved. -</span>
        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">沪ICP备18021037号-2</a>
        <!-- <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=" target="_blank" style="margin-left: 25px">粤公安网备********号</a> -->
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        // 合作伙伴数组
        cooperation: [{
            imgSrc: require("../assets/imgs/logo.png"),
            text: "美助未来",
          },
          {
            imgSrc: require("../assets/imgs/logo.png"),
            text: "美助将来",
          },
        ],
      };
    },
  };
</script>

<style lang="less" scoped>
  /* 页脚 */
  .footer {
    width: 100%;
    /* height: 250px; */
    background-color: #f8f8f8;

    .footerIn {
      //width: 1220px;
      margin: 0 auto;
      background: #fff;
      .footerInTop {
        /* height: 190px; */
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        // border-bottom: 1px solid #e5e5e5;        
        font-size: 12px;
        color: #666666;
        padding-top: 20px;
        
      
        .footerInTopLeftTitle,
        .footerInTopRightTitle {
          font-size: 20px;
          color: #333333;
          font-weight: bold;          
        }

        .footerInTopLeft {
          span {
            margin: 0 7px;
            color: #333;
          }
        }

        .declaration{
          margin-left: 20px;
          a{
            color: #409eff;
          }
        }
      }

      .footerInBottom {
        padding: 16px 20px;
        box-sizing: border-box;
        font-size: 12px;
        color: #999999;
        text-align: center;
        a {
          color: #999999;
          text-decoration: none;
        }
      }
    }
  }


  @media screen and (max-width: 640px) {
      .declaration{
        display: none;
      }
  }
  
</style>