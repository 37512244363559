<template>
  <div class="page-container">
    <!-- 轮播图 -->
    <el-carousel :interval="5000" arrow="always" height="380px" class="banner-container" v-if="bannerList.length != 0"
      :indicator-position="bannerList.length > 1 ? '' : 'none'" :class="bannerList.length > 1 ? '' : 'hide_arrow'">
      <el-carousel-item v-for="(item,index) in bannerList" :key="index">
        <div class="banner_item" @click="toRoute(item.linkUrl)" :style="{backgroundImage:'url('+item.source+')'}">
        </div>
      </el-carousel-item>
    </el-carousel>


    <el-alert class="page-tip" title="温馨提示，电脑端体验更佳" type="warning" center="">
    </el-alert>
    <!-- <div class="chunkBox">
      <div>
        <div class="chunkItem" :style="{backgroundImage:'url('+require('../assets/imgs/chunk1.png')+')'}">
          <p class="chunkTitle">关键词分析</p>
          <div class="chunkHref chunkHref0" @click="toRoute('/SearchListings')">立即跳转</div>
        </div>
      </div>
      <div>
        <div class="chunkItem" :style="{backgroundImage:'url('+require('../assets/imgs/chunk2.png')+')'}">
          <p class="chunkTitle">礼品单</p>
            <div class="chunkHref chunkHref1" @click="toRoute('/goodsList')">立即跳转</div>
        </div>
      </div>
      <div>
        <div class="chunkItem" :style="{backgroundImage:'url('+require('../assets/imgs/chunk3.png')+')'}">
          <p class="chunkTitle">浏览单</p>
          <div class="chunkHref chunkHref2" @click="toRoute('/manage/taobao')">立即跳转</div>
        </div>
      </div>
      <div>
        <div class="chunkItem" :style="{backgroundImage:'url('+require('../assets/imgs/chunk4.png')+')'}">
          <p class="chunkTitle">竞品分析</p>
          <div class="chunkHref chunkHref3" @click="toRoute('/JingPinAnalyse')">立即跳转</div>
        </div>
      </div>
    </div> -->


    <!-- 热门功能 -->
    <div class="indexBanner">
      <div class="indexBannerIn">
        <div class="indexBannerInTitle">热门功能</div>
        <div style="display: flex;margin-top:10px;flex-wrap: wrap;justify-content:center;">
          <div class="indexBannerDIV" v-for="(item, index) in hotFunction" :key="index" @click="toRoute(item.url)">
            <img class="indexBannerImg" :src="item.imgSrc" alt="" />
            <!-- <img src="../assets/imgs/一键查旺旺.png" alt=""> -->
            <div class="indexBannerText">
              {{ item.text }}
              <div class="video_teach"   @click.stop="openVideo(item.url)" v-show="isExist(item.url)">
                <i class="el-icon-video-play"></i>视频教学
              </div>
            </div>
            <!-- <div class="indexBannerBtn"><router-link :to="item.url" tag="span">立即使用</router-link></div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- 服务优势 -->
    <div class="indexAdvantage">
      <div class="indexAdvantageInTitle">{{title}}产品服务优势</div>
      <div style="display: flex; flex-wrap: wrap; justify-content: space-between;  ">
        <div class="indexAdvantageInDIV" v-for="(item, index) in advantageOfService" :key="index">
          <img class="indexAdvantageInImg" :src="item.imgSrc" alt="" />
          <!-- <img src="../assets/imgs/一键查旺旺.png" alt=""> -->
          <div class="indexAdvantageInText">{{ item.text }}</div>
        </div>
      </div>
    </div>


    <el-dialog :title="videoTitle" :visible.sync="dialogPlay" width="50%" @close="closeDialog">
      <video :src="videoUrl" controls autoplay class="video" ref="dialogVideo" width="100%"></video>
    </el-dialog>


    <!-- 页脚 -->
    <indexFooter></indexFooter>
  </div>
</template>

<script>
  import indexFooter from "@/components/indexFooter.vue"; //这里是引入请求

  import {videoData} from "@/utils/videoData";

  export default {
    data() {
      return {
        // 块状跳转
        // bannerList: [
        //   {
        //     pic: require('../assets/imgs/banner1.jpg'),
        //     url: "/manage/taobao"
        //   },
         
        //   {
        //     pic: require('../assets/imgs/banner2.jpg'),
        //     url: "/QueryWant"
        //   },

        //   {
        //     pic: require('../assets/imgs/banner3.jpg'),
        //     url: "/SearchListings"
        //   },
        // ],
        dialogPlay: false,
        videoTitle:'',
        videoUrl: '',
        // 热门功能数组
        hotFunction: [
          {
            imgSrc: require("../assets/imgs/icon_zhaoyaojing.png"),
            text: "一键查旺旺",
            url: "/QueryWant",
          },
          // {
          //   imgSrc: require("../assets/imgs/icon_pingjia.png"),
          //   text: "蓝海词查询",
          //   url: "/SearchListings",
          // },
          {
            imgSrc: require("../assets/imgs/icon_liuliang.png"),
            text: "商品透视",
            url: "/CategoriesQuery",
          },
          {
            imgSrc: require("../assets/imgs/icon_dabiao.png"),
            text: "旺旺打标",
            url: "/WantWantMarking",
          },
          {
            imgSrc: require("../assets/imgs/icon_xiaoliang.png"),
            text: "关键字卡首屏",
            url: "/keywordCard",
          },
          {
            imgSrc: require("../assets/imgs/icon_guanjianci.png"),
            text: "商品排名",
            url: "/KeywordRanking",
          },
          // {
          //   imgSrc: require("../assets/imgs/autoSchedule.png"),
          //   text: "自动排单表",
          //   url:"/KeywordTransaction"
          // },

          {
            imgSrc: require("../assets/imgs/icon_yanhao.png"),
            text: "搜索入池检测",
            url: "/ItemCheckInPool",
          },
          // {
          //   imgSrc: require("../assets/imgs/icon_shangpin.png"),
          //   text: "竞品分析",
          //   url: "/JingPinAnalyse",
          // },
          {
            imgSrc: require("../assets/imgs/icon_touxi.png"),
            text: "DSR智能计算",
            url: "/DSRcalculator",
          },

          {
            imgSrc: require("../assets/imgs/icon_zhishu.png"),
            text: "指数还原",
            url: "/OnLineExponentialReduction",
          },

          {
            imgSrc: require("../assets/imgs/icon_shuxing.png"),
            text: "属性查询",
            url: "/AttributeQuery",
          },

          {
            imgSrc: require("../assets/imgs/icon_suk_fenxi.png"),
            text: "SKU占比分析",
            url: "/SkuAccountedFor",
          },
          {
            imgSrc: require("../assets/imgs/icon_shoucang.png"),
            text: "问大家",
            url: "/CommodityAnswer",
          },
          // {
          //   imgSrc: require("../assets/imgs/icon_dindan.png"),
          //   text: "淘客订单查询",
          //   url: "/TaokeOrderInquiry",
          // },
          {
            imgSrc: require("../assets/imgs/icon_ling.png"),
            text: "淘口令",
            url: "/NewPassword",
          },
        ],
        // 服务优势数组
        advantageOfService: [{
            imgSrc: require("../assets/imgs/advantage1.png"),
            text: "提供多种实用工具，快速精准匹配结果无需等待节省效率，多维度整合数据资源，为客户提供决策支持，成本做到市场最低，平台功能备受用户使用好评",
          },
          {
            imgSrc: require("../assets/imgs/advantage2.png"),
            text: "目前主要服务于淘宝、天猫电商平台专注软件大数据产品开发，同时致力于帮助第三方服务商，电商培训机构工作室等解决客户资源留存变现等问题，未来将携手合伙人共同合作，形成了销售增长数字化营销解决方案",
          },
          {
            imgSrc: require("../assets/imgs/advantage3.png"),
            text: "便捷的好友分享机制，让更多人了解这个平台的优点和功能，让更多人了解到这个平台的全面支持和便捷性，让更多人享受到这个平台带来的实惠和优惠",
          },
          {
            imgSrc: require("../assets/imgs/advantage4.png"),
            text: "开通会员享受更多优惠服务，开通会员送对应的功能使用次数，积分充值充值1000元额外赠送1万积分相当于赠送100元，充值越多送的越多",
          },
        ],

      };
    },
    components: {
      indexFooter,
    },
    mounted() {
      
    },
    methods: {
      chunkSkip(url) {
        if (url) {
          if (this.$store.state.token != null) {
            console.log(url + '?bridgeToken=' + this.$store.state.token)
            window.open(url + '?bridgeToken=' + this.$store.state.token)
          } else {
            window.open(url)
          }
        } else {
          return
        }
      },
      openVideo(url) {
        let videoItem = videoData[url];
        if(!videoItem){
            this.$message.error("暂无教学视频");
            return;
        }
        this.dialogPlay = true;
        this.videoTitle = "教学视频：" + videoItem.name;
        this.videoUrl = videoItem.url;
      },
      isExist(url){
          let videoItem = videoData[url];
          if(!videoItem){
              return false;
          }

          return true;
      },
      closeDialog() {
        this.dialogPlay = false
        this.videoUrl = ''
      }
    },
    computed:{
      title(){
        return this.$store.state.title;
      },
      bannerList(){
        let advertList = this.$store.state.advertList;
        if(advertList){
            return advertList.filter((item) => {
              return item.type == "banner";
            });
        }

        return [];
      }
    }
  };
</script>

<style lang="less" scoped>
  .page-container {
    height: 100%;
    margin: auto;
  }

  .page-tip {
    display: none;
    margin-bottom: 10px;
    text-align: center;
  }



  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }

  /deep/.el-carousel__arrow {
    border-radius: 0;
    width: 30px;
    height: 50px;
    font-size: 20px;
  }

  // /deep/.el-carousel__arrow--left {
  //   left: 420px;
  // }

  // /deep/.el-carousel__arrow--right {
  //   right: 420px;
  // }
  .banner-container {
    // overflow-x: inherit !important;
    width: 100%;
  }

  .banner_item {
    min-height: 120px;
    height: 100%;
    background-repeat: no-repeat;
    cursor: pointer;
    background-size: 100% 100%;
    background-position: center;
  }

  /* 热门功能 */
  .indexBanner {
    //height: 500px;
    width: 70%;
    min-height: 400px;
    margin: 0 auto;
    background-color: #fff;

    /* 热门功能 */
    .indexBannerIn {
      // width: 1220px;
      width: 100%;
      margin: 0 auto;

      .indexBannerInTitle {
        text-align: center;
        font-size: 24px;
        color: #333333;
        padding: 20px 0;
        font-weight: bold;
      }

      .indexBannerDIV {
        display: flex;
        align-items: center;
        width: 180px;
        height: 70px;
        padding: 0 20px;
        margin: 0 20px 20px;
        background: #ffffff;
        border-radius: 2px;
        text-align: center;
        box-shadow: 0 7px 23px 1px hsla(0, 0%, 75%, .2);
        border-radius: 14px;
        cursor: pointer;
        overflow: hidden;
        transition: all 0.2s linear;
        font-size: 14px;

        .indexBannerImg {
          width: 42px;
          height: 42px;
          margin-right: 10px;
        }

        .indexBannerText {
          font-weight: 700;
          color: #333;

          .video_teach {
            font-size: 12px;
            text-align: left;

            &:hover {
              color: #409EFF;
            }
          }
        }

        .indexBannerBtn {
          width: 120px;
          height: 32px;
          line-height: 32px;
          border: 1px solid #5d7cff;
          border-radius: 16px;
          color: #5d7cff;
          margin: 0 auto;
          cursor: pointer;
        }

        .indexBannerBtn:hover {
          background-color: #5d7cff;
          color: #fff;
        }
      }

      .indexBannerDIV:hover {
        box-shadow: 0 15px 10px -5px #bfbfbf;
      }
    }
  }

  /* 服务优势 */
  .indexAdvantage {
    // min-height: 540px;
    width: 70%;
    padding: 0 0 20px;
    margin: 0 auto;

    .indexAdvantageInTitle {
      text-align: center;
      font-size: 24px;
      color: #333333;
      letter-spacing: 0.22px;
      padding: 20px 0;
      font-weight: bold;
    }

    .indexAdvantageInDIV {
      margin: 10px 0px;
      padding: 8px;
      // width: 589px;
      width: 48%;
      min-width: 320px;
      // height: 160px;
      background-color: #fff;
      display: flex;
      padding: 20px 50px 20px 40px;
      box-sizing: border-box;
      overflow: auto;
      align-items: center;
    }

    .indexAdvantageInImg {
      width: 60px;
      height: 60px;
      margin-right: 30px;
    }

    .indexAdvantageInText {
      font-size: 12px;
      color: #333333;
      letter-spacing: 0.11px;
      flex: 1;
    }
  }

  /* 视频教学 */
  .videoTeaching {
    background-color: #fff;

    .videoTeachingIn {
      margin: 0 auto;
      height: 485px;
      width: 1220px;
    }

    .videoTeachingInTitle {
      text-align: center;
      font-size: 28px;
      color: #333333;
      letter-spacing: 0.22px;
      padding: 60px 0;
      font-weight: bold;
    }

    .videoTeachingInDIV {
      width: 380px;
      text-align: center;

      .video {
        width: 380px;
        /* height: 171px; */
        margin-bottom: 24px;
        border-radius: 8px;
      }

      .videoTeachingInText {
        font-size: 18px;
        color: #333333;
        letter-spacing: 0.14px;
      }
    }
  }

  .chunkBox {
    display: flex;
    justify-content: space-around;
    max-width: 1500px;
    // min-width: 1220px;
    margin: 0 auto;
    padding: 50px 0;

    .chunkItem {
      width: 300px;
      height: 172px;
      background-repeat: no-repeat;
      background-size: 100% auto;

      .chunkTitle {
        font-size: 24px;
        color: #fff;
        text-align: center;
        padding-top: 25px;
        padding-bottom: 45px;
      }

      .chunkHref {
        font-size: 14px;
        height: 30px;
        border-radius: 15px;
        background-color: #fff;
        width: 120px;
        text-align: center;
        cursor: pointer;
        margin: 0 auto;
        line-height: 30px;
        border: 1px solid #fff;

        &:hover {
          color: #fff;
        }
      }

      .chunkHref0 {
        &:hover {
          background-color: #5d75fe;
        }
      }

      .chunkHref1 {
        &:hover {
          background-color: #fa6780;
        }
      }

      .chunkHref2 {
        &:hover {
          background-color: #816be1;
        }
      }

      .chunkHref3 {
        &:hover {
          background-color: #fe5c5a;
        }
      }

      .chunkTip {
        font-size: 12px;
        color: #fff;
        text-align: center;
        margin: 5px 40px 8px;
        height: 40px;
      }
    }
  }





  @media screen and (max-width: 640px) {
    
    .banner-container {
      /deep/.el-carousel__container {
        height: 200px !important;
      }

    }

    .indexAdvantage,.indexBanner{
      width:100%;
    }

    .indexAdvantage .indexAdvantageInDIV {
      width: 100%;
      height: auto;
    }

    .indexBanner {

      .indexBannerIn {

        .indexBannerDIV {
          padding: 4px;
          height: auto;
          width: 40%;
          margin: 4px 10px;
        }
      }
    }



    .page-tip {
      display: block;
    }

  }

  .hide_arrow {
    .el-carousel__arrow {
      display: none;
    }
  }
</style>